import React, { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';

const App = () => {
  const [spec, setSpec] = useState(null);

  useEffect(() => {
    const fetchSpec = async () => {
      try {
        const response = await fetch('/swagger.json');
       
        const data = await response.json();
       setSpec(data);
      } catch (error) {
        console.error("Error loading JSON file:", error);
      }
    };

    fetchSpec();
  }, []);

  return (
    <div>
      {spec ? (
        <RedocStandalone spec={spec} />
      ) : (
        <p>Loading JSON file...</p>
      )}
    </div>
  );
};

export default App;